<!--  -->
<template>
  <div class="setting-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '设置',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <div class="top">
        <van-list>
          <van-cell class="item" @click="handleItem('changePhone')">
            <p class="left">
              <van-image
                width=".4rem"
                height=".4rem"
                fit="cover"
                :src="require('@/assets/images/setting/phone.png')"
              />
              <span class="title">修改手机号</span>
            </p>
            <p class="icon"><van-icon name="arrow" size="14" /></p>
          </van-cell>
          <van-cell class="item" @click="handleItem('changePass')">
            <p class="left">
              <van-image
                width=".4rem"
                height=".4rem"
                fit="cover"
                :src="require('@/assets/images/setting/pass.png')"
              />
              <span class="title">修改密码</span>
            </p>
            <p class="icon"><van-icon name="arrow" size="14" /></p>
          </van-cell>
        </van-list>
      </div>
      <div class="bottom">
        <van-list>
          <van-cell class="item" @click="handleItem('about')">
            <p class="left">
              <van-image
                width=".4rem"
                height=".4rem"
                fit="cover"
                :src="require('@/assets/images/setting/feedBcak.png')"
              />
              <span class="title">关于</span>
            </p>
            <p class="icon"><van-icon name="arrow" size="14" /></p>
          </van-cell>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import TopWrapper from "@/components/topWrapper/index.vue";
import { Toast } from "vant";

export default {
  data() {
    return {};
  },

  components: { TopWrapper },

  computed: {},

  mounted() {},

  methods: {
    handleItem(name) {
      if (!name) {
        Toast("该功能暂未开通");
        return;
      } else {
        this.$router.push(`/${name}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;
  .main-wrapper {
    margin-top: 0.01rem;
    .top {
      height: 2.62rem;
      background: #ffffff;
      margin: 0.1rem 0rem;
      ::v-deep .van-cell {
        height: 1.3rem;
        .van-cell__value--alone {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          line-height: 0.8rem;
        }
      }
      .left {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
          margin-left: 0.28rem;
          font-size: 0.3rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
        }
      }
      .icon {
        width: 0.2rem;
        height: 0.2rem;
      }
    }
    .bottom {
      margin-top: 0.19rem;
      background: #ffffff;
      ::v-deep .van-cell {
        height: 1.3rem;
        .van-cell__value--alone {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          line-height: 0.8rem;
        }
      }
      .left {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
          margin-left: 0.28rem;
          font-size: 0.3rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
        }
      }
      .icon {
        width: 0.2rem;
        height: 0.2rem;
      }
    }
  }
}
</style>
